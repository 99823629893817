.jobs {
    cursor: pointer;
    color: rgb(216, 72, 61);
}

.jobs:hover {
    text-decoration: underline;
}

.row {
    text-align: start;
}

.form-label {
    color: black;
    font-weight: 700;
}
.relative{
    position: relative;
}
.centered-text {
    position: absolute;
    top: 53%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    width: 100%;
}

.main-team-title {
    font-size: min(9.1vw,70px);
    font-weight: 600;
    margin-bottom: 1.5rem;
}

.job-img {
    /* height: 91vh; */
    width: 100%;
    object-fit: contain;
    max-height: 91vh;
  }
  .sub-team-title{
    font-size: min(5.1vw,28px);
    margin-bottom: 4.5rem;

  }
  .text-size{
    font-size: 20px;
  }

@media only screen and (max-width: 768px) {
    .main-team-title {
        /* font-size: 3rem; */
        font-size: min(8.0vw,70px);
        margin-bottom: 5px;
    }
    .sub-team-title{
        font-size: min(5.1vw,22px);
        margin-bottom: 1rem;
      }
    .job-img{
        /* height: 91vh !important; */
    }
  }
  @media (max-width: 500px) {
    .text-size{
        font-size: 18px;
      }
  }