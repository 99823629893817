.min-img-faqs {
    height: 60vh;
    width: 100%;
    object-fit: cover;
}

.centered-faqs {
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    width: 100%;
}

.main-img-title-faqs {
    font-size: 6rem;
    font-weight: 600;
    margin-bottom: 2rem;
}

.sub-title-faqs {
    font-size: 2.5rem;
    font-weight: 100;
    margin-bottom: 2rem;
}

.heading {
    font-size: 2rem;
    font-weight: 600;
    margin-top: 2.5rem;
}
.paragraph{
    padding: 2rem 4rem;
}
.faqs-main-div {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 4rem;
}

@media only screen and (max-width: 600px) {

    /*Big smartphones [426px -> 600px]*/
    .faqs-main-div {
        margin: 1rem;
    }
}

.text-start {
    text-align: start !important;
}

.accordion-item {
    width: 90vw;
    margin-bottom: 1rem;
    border: none !important;
}

.accordion-button {
    font-weight: bold !important;

}

.accordion-button:focus {
    border-color: transparent !important;
    box-shadow: none !important;
}

.accordion-button:not(.collapsed) {
    color: #000000 !important;
    background-color: transparent !important;
}

.accordion-button:not(.collapsed)::after {
    background-color: transparent !important;
}

.career-link{
    color: #d8483d;
    cursor: pointer;
}

.career-link:hover{
    text-decoration: underline;
    color: #d8483d; 
}