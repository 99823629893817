.coverImg {
    /* background-image: url(../../assets/images/cover-image.jpg); */
    width: 100%;
    height: 500px;
    object-fit: cover;
    /* background-repeat: no-repeat;
    background-size: contain; */

}

.card {
    background: white;
    border-radius: 15px;
    box-shadow: 0px 0px 3px lightgrey

}

@media (max-width: 992x){
    .pickup-txt{
        left: 100px;
    }
}
@media (max-width:768px){
    .pickup-txt{
        left: 28px !important; 
        padding-right: 20px;
    }
    br{
        display: none;
    }
}