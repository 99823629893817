
/* @font-face {
  font-family: poppins;
  font-style: normal;
  font-weight: normal;
  src: url('./assets/fonts/Poppins/Poppins-BlackItalic.ttf')
    format(“truetype”);
} */


@font-face {
  font-family: playlist;
  font-style: normal;
  font-weight: normal;
  src: url('./assets/fonts/PlayList/Playlist\ Script.otf')
    format(“truetype”);
}

:root{
  margin: 0;
  font-family:poppins !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: poppins;
}
