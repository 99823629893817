.body {
  padding: 2rem 4rem;
  font-size: 16px;
}

.body>div>p {
  text-align: start !important;
}

.body>h1 {
  margin: 2rem 0;
}
.relative{
  position: relative;
}
.row {
  display: flex;
  flex-wrap: wrap;
  padding: 0 4px;
}
.aboutus-txt{
  font-size: 20px;
}

/* Create four equal columns that sits next to each other */
.column {
  flex: 25%;
  max-width: 25%;
  padding: 0 4px;
}

.column img {
  margin-top: 8px;
  vertical-align: middle;
  width: 100%;
}
.para-txt{
  font-size: 16px;
  margin-bottom: 5px;
}

/* Responsive layout - makes a two column-layout instead of four columns */
@media screen and (max-width: 800px) {
  .column {
    flex: 50%;
    max-width: 50%;
  }
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .column {
    flex: 100%;
    max-width: 100%;
  }
}

.bottom-right {
  position: absolute;
  /* top: 77%; */
  right: 2%;
  bottom: 15%;
  /* left: 75%; */
  /* transform: translate(-50%, -50%); */
  color: white;
  width: 100%;
  max-width: 450px;
}
.we-stay-txt{
  width: 90%;
  margin: 10px;
}
.mob{
  display: none;
}
.bottom-right>.main-title {
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 2rem;
}

@media only screen and (max-width: 870px) {
  .bottom-right {
    /* right: -5%; */
    max-width: 350px;
  }
  .we-stay-txt{
    width: 90%;
  }
  .para-txt{
    font-size: 14px;
  }
}
@media only screen and (max-width: 600px) {
  .bottom-right {
    right: 2%;
    max-width: 250px;
  }
  .we-stay-txt{
    width: 90%;
  }
  .para-txt{
    font-size: 12px;
  }
}
@media only screen and (max-width: 600px) {
  .bottom-right {
    right: 2%;
    bottom: 10%;
    max-width: 198px;
  }
  .we-stay-txt{
    width: 90%;
  }
  .para-txt{
    font-size: 10px;
  }
}


.stay{
  /* font-family: scripts !important;
  font-style: italic;
  font-size: 4rem; */
  font-family: 'playlist';
  font-size: 3rem;
}

@media (max-width:768px){
  .man-img{
    height: 91vh;
    width: 100%;
    object-fit: cover;
  }
  .mobText{
    font-size: 18px;
  }
  
}

@media (max-width:500px){
  .mob{
    display: block;
  }
  .bottom-right{
    /* top: 58% !important; */
  }
  .body {
    padding: 2rem 1.5rem;
    font-size: 1.0rem;
  }
  .aboutus-txt{
    font-size: 18px;
  }
  
}
