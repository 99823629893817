.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 4px 7.5rem;
}

.min-img {
  height: 91vh;
  width: 100%;
  object-fit: cover;
}
.landing-page{
  position: relative;
}
.main-img-title {
  font-size: 9rem;
  font-weight: 600;
  margin-bottom: 2rem;
}

.main-img-title-mob {
  font-size: 3rem;
  font-weight: 600;
  margin-bottom: 2rem;
  

}
.footer-address{
  text-align: center;
  color: white;
  margin-top: 10px;
  margin-bottom: 0;
}
.main-img-title-mob span{
  font-family: 'playlist';
  font-size: 5rem;
}

.main-img-title span {
  font-family: 'playlist';
  font-size: 13rem;
}

.container {
  position: relative;
  text-align: center;
  color: white;
}

.centered {
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .centered {
    /* position: absolute;
    top: 53%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    width: 100%; */
  }
}


.hungry-section {
  height: 300px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: white;
}

.hungry-section>h1 {
  font-size: 5.5rem;
  font-weight: 600;
  color: rgb(216, 72, 61);
  letter-spacing: -2px;
}

.backGroundImg {
  height: 990px;
  width: 100%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: contain;
  background-position: center center;
}

hr {
  height: 2px;
  width: 100%;
  background-color: #ffffff;
  border: none;
}

/* button {
  border-radius: 0 !important;
} */


@media screen and (min-width: 767px) and (max-width: 1300px) {
 
  .main-img-title {
    font-size: 6rem;
  }

  .nav>img {
    height: 50px !important;
    width: 150px !important;
  }
}

.mr-1 {
  margin-right: 1rem;
}

a {
  text-decoration: none;
  color: white;
}

.btn {
  border-radius: 0 !important;
}

.skip {
  font-family: scripts !important;
  font-style: italic;
  font-size: 9rem;
}
@media (max-width: 1024px){
  .min-img{
    height: unset !important;
    object-fit: contain;
  }
}

@media (max-width: 400px){
  .min-img{
    /* height: 32vh !important; */
  }
  .centered img{
    width: 38%;
  }
}